<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Indicate whether each of the following is either a chemical or physical change/property.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Property</th>
            <th style="font-size: 15px">Physical</th>
            <th style="font-size: 15px">Chemical</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>a) The melting point of water is 0 °C.</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              b) When an aqueous solution with a pH less than 7 is added to sodium bicarbonate,
              bubbling occurs.
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>c) Cooking an egg.</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>d) The lowering of vapor pressure after salt is added to a liquid.</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>e) Combination of yellow and blue paint to create a green paint.</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>f) Formation of solid after reagent combination.</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>g) Crystal formation upon solution formation.</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Chem1LC_LabFundamentals_Q8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
    };
  },
};
</script>
